import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2C2.44772 2 2 2.44772 2 3V10C2 10.5523 2.44772 11 3 11H10C10.5523 11 11 10.5523 11 10V3C11 2.44772 10.5523 2 10 2H3ZM6.5 8C7.88071 8 9 6.88071 9 5.5C9 4.11929 7.88071 3 6.5 3C5.11929 3 4 4.11929 4 5.5C4 6.88071 5.11929 8 6.5 8Z"
      fill="#6D65FE"
    />
    <rect x="4" y="9" width="5" height="1" rx="0.5" fill="#8CA0F7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 2C13.4477 2 13 2.44772 13 3V10C13 10.5523 13.4477 11 14 11H21C21.5523 11 22 10.5523 22 10V3C22 2.44772 21.5523 2 21 2H14ZM17.5 8C18.8807 8 20 6.88071 20 5.5C20 4.11929 18.8807 3 17.5 3C16.1193 3 15 4.11929 15 5.5C15 6.88071 16.1193 8 17.5 8Z"
      fill="#5576FB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 13C2.44772 13 2 13.4477 2 14V21C2 21.5523 2.44772 22 3 22H10C10.5523 22 11 21.5523 11 21V14C11 13.4477 10.5523 13 10 13H3ZM6.5 19C7.88071 19 9 17.8807 9 16.5C9 15.1193 7.88071 14 6.5 14C5.11929 14 4 15.1193 4 16.5C4 17.8807 5.11929 19 6.5 19Z"
      fill="#5576FB"
    />
    <rect x="4" y="20" width="5" height="1" rx="0.5" fill="#8CA0F7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 13C13.4477 13 13 13.4477 13 14V21C13 21.5523 13.4477 22 14 22H21C21.5523 22 22 21.5523 22 21V14C22 13.4477 21.5523 13 21 13H14ZM17.5 19C18.8807 19 20 17.8807 20 16.5C20 15.1193 18.8807 14 17.5 14C16.1193 14 15 15.1193 15 16.5C15 17.8807 16.1193 19 17.5 19Z"
      fill="#6D65FE"
    />
    <rect x="15" y="9" width="5" height="1" rx="0.5" fill="#8CA0F7" />
    <rect x="15" y="20" width="5" height="1" rx="0.5" fill="#8CA0F7" />
  </svg>
);
