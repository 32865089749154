import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_340_68)">
      <rect x="1" y="4" width="15" height="10" rx="1" fill="#6D65FE" />
      <rect x="20" y="4" width="14" height="10" rx="1" fill="#5576FB" />
      <rect x="1" y="16" width="15" height="3" rx="1.5" fill="#8CA0F7" />
      <rect x="20" y="16" width="14" height="3" rx="1.5" fill="#8CA0F7" />
    </g>
    <defs>
      <clipPath id="clip0_340_68">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
