import React, { useState, useEffect } from 'react';
import { BallBeat } from 'react-pure-loaders';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import { withSelect } from '@wordpress/data';
import NavbarTop from './layout/NavbarTop';
import NavbarVertical from './layout/NavbarVertical';
import DashboardMain from './dashboardMain/DashboardMain';
import * as ROUTES from '../../constants/routes';
import CustomizeApp from './customizeApp/CustomizeApp';
// import CustomizeApp from './components/dashboard/customizeApp/CustomizeApp';
import MenuEditor from '../InAppPageMenuEditor';
import UpdateApp from './UpdateApp';
import PushNotificationForm from './pushNotification/PushNotificationForm';
import SettingsPage from './settingsPage/SettingsPage';
import SubscriptionData from './subscriptionData';
// import MakePayment from './subscriptionData/MakePayment';
import ConnectStore from './connectStore';
import SubscriptionExpired from './MessageScreens/SubscriptionExpired';
import SubscriptionCheck from '../RouterHOC/SubscriptionCheck';
import CONSTANT from '../../lib/constants';
import SmartBanner from './promoteApp/smartBanner';
import EmailFooter from './promoteApp/emailFooter';
import CustomerData from './customerData';
import PushAutomation from '../PushnotificationAutomation';
import AccountSuspended from './MessageScreens/AccountSuspended';
import ChatWidget from './ChatWidget';

function Dashboard({ project, user, pluginResponse, isAdmin }) {
  const [changeName, setChangeName] = useState(false);
  const [nameValue, setNameValue] = useState({});
  const [newPageVale, setNewPage] = useState(undefined);
  const { paymentStatus, feature, template, id } = project;
  useEffect(() => {
    // redirect to manage-v2 for new woocommerce project
    if (
      project &&
      parseInt(project.id) >= 149547 &&
      project.template === 'woocommerce'
    ) {
      window.location.href = ROUTES.redirectToV2(project.id);
    }
  }, []);
  let isExpired = isAdmin
    ? false
    : paymentStatus === CONSTANT.PROJECT_PAYMENT_EXPIRED ||
      paymentStatus === CONSTANT.PROJECT_PAYMENT_EXPIRED_COMPLETE;

  const isAccountSuspended = project.status === CONSTANT.ACCOUNT_SUSPENDED;

  isExpired = isExpired || isAccountSuspended;

  const history = useHistory();
  if (pluginResponse === undefined) {
    return (
      <div className="wrap overflow-auto pr-3 d-flex justify-content-center align-items-center">
        <BallBeat color="#123abc" loading />
      </div>
    );
  }
  if (pluginResponse === false)
    return (
      <React.Fragment>
        <NavbarTop
          imgSrc="https://storage.googleapis.com/stateless-appmaker-pages-wp//2020/02/appmaker-logo-color.svg"
          project={project}
          endDate={project.endDate}
          isExpired={isExpired}
        />
        <ConnectStore pluginResponse={pluginResponse} />
      </React.Fragment>
    );
  if (!project.id) return <div>No apps</div>;
  return (
    <Router history={history}>
      <div className="manage-dashboard">
        <NavbarVertical
          isExpired={isExpired}
          appName={project.name}
          appLogo={project.icon}
          appId={project.id}
          nameChanged={changeName ? nameValue : false}
          newPage={newPageVale}
          afterNewPage={data => {
            setNewPage(undefined);
          }}
        />

        <div className="main-container">
          <div className="container-fluid p-0">
            <NavbarTop
              pluginResponse={pluginResponse}
              userName={user.name}
              endDate={project.endDate}
              project={project}
              isExpired={isExpired}
            />
            <ChatWidget
              feature={feature}
              template={template}
              projectId={id}
              project={project}
            />
            <Route
              exact
              path={ROUTES.SUBSCRIPTION_EXPIRED}
              component={SubscriptionExpired}
              isExpired={isExpired}
              project={project}
            />
            <Route
              exact
              path={ROUTES.ACCOUNT_SUSPENDED}
              component={AccountSuspended}
              isExpired={isExpired}
              project={project}
            />
            <SubscriptionCheck
              isAdmin={isAdmin}
              isExpired={isExpired}
              exact
              project={project}
              path={ROUTES.DASHBOARD_MAIN}
              component={DashboardMain}
            />
            <SubscriptionCheck
              isAdmin={isAdmin}
              isExpired={isExpired}
              exact
              project={project}
              pageNameChange={data => {
                setChangeName(true);
                setNameValue(data);
              }}
              newPage={data => {
                setNewPage(data);
              }}
              path={`${ROUTES.DASHBOARD_MAIN}/(inAppPage)/:inAppPageId?`}
              component={CustomizeApp}
            />
            <SubscriptionCheck
              isAdmin={isAdmin}
              isExpired={isExpired}
              exact
              project={project}
              path={`${ROUTES.DASHBOARD_MAIN}/menu`}
              component={MenuEditor}
            />
            <SubscriptionCheck
              isAdmin={isAdmin}
              isExpired={isExpired}
              project={project}
              path={`${ROUTES.DASHBOARD_MAIN}/update-app`}
              component={UpdateApp}
            />
            {/* <Route
                    path={ROUTES.CUSTOMIZE_APP}
                    render={() => <CustomizeApp appId={project.project.name} />}
                  /> */}
            {/* <Route path={ROUTES.ADD_NEW_PAGE} component={SelectPageType} /> */}

            <SubscriptionCheck
              isAdmin={isAdmin}
              isExpired={isExpired}
              project={project}
              path={ROUTES.PUSH_NOTIFICATION}
              component={PushNotificationForm}
            />
            <SubscriptionCheck
              isAdmin={isAdmin}
              isExpired={isExpired}
              project={project}
              path={ROUTES.SMART_BANNER}
              component={SmartBanner}
            />
            <SubscriptionCheck
              isAdmin={isAdmin}
              isExpired={isExpired}
              project={project}
              path={ROUTES.EMAIL_FOOTER}
              component={EmailFooter}
            />
            <SubscriptionCheck
              isAdmin={isAdmin}
              isExpired={isExpired}
              project={project}
              path={ROUTES.SETTINGS}
              component={SettingsPage}
            />
            <SubscriptionCheck
              isAdmin={isAdmin}
              project={project}
              path={`${ROUTES.linkToSubscription()}`}
              component={SubscriptionData}
            />
            <SubscriptionCheck
              isAdmin={isAdmin}
              isExpired={isExpired}
              project={project}
              path={`${ROUTES.linkToCustomerData()}`}
              component={CustomerData}
            />
            <SubscriptionCheck
              isAdmin={isAdmin}
              isExpired={isExpired}
              project={project}
              path={ROUTES.PUSH_AUTOMATION}
              component={PushAutomation}
            />
            {/* <Route
              path={ROUTES.MAKE_PAYMENT}
              component={MakePayment}
                /> */}
          </div>
        </div>
      </div>
    </Router>
  );
}

export default withSelect((select, ownProps) => {
  const user = select('appmaker/core').getUser();
  const isAdmin = user && user.email.includes('@appmaker.xyz');
  const pluginResponse = select(
    'appmaker/core'
  ).getCurrentProjectPluginResponse();
  return {
    pluginResponse,
    isAdmin
  };
})(Dashboard);
