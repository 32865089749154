import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  CustomInput
} from 'reactstrap';
import './style.css';
import PlanListing from './components/PlanListing';
import * as paymentPlans from './plans';
import { API_URL } from '../../../../constants/routes';
import { trackEvent } from '../../../../utils/tracker';

const ChoosePlan = forwardRef(
  (
    {
      showPaymentButton = true,
      paymentText = 'Make Payment',
      projectId,
      paymentButtonClass = 'btn btn-light mt-2',
      template = 'woocommerce',
      email = ''
    },
    ref
  ) => {
    const [modal, setModal] = useState(false);
    const [yearly, setYearly] = useState(false);
    const [planId, setPlanId] = useState('growth');
    const [skipToggleButton, setSkipToggleButton] = useState(false);
    const plans = paymentPlans[template];
    const plansCount = Object.keys(plans).length;

    useImperativeHandle(ref, () => ({
      toggle() {
        setModal(!modal);
      }
    }));

    const toggle = () => {
      // console.log('Toggle clicked');
      setYearly(false);
      if (!modal) {
        try {
          trackEvent('checkout_step_plan_choose', { project_id: projectId });
        } catch (error) { }
      }
      setModal(!modal);
    };
    const billToggle = () => {
      setYearly(!yearly);
      trackEvent('checkout_year_toggle', {
        project_id: projectId,
        yearly
      });
    }

    function proceed() {
      if (template === 'woocommerce') {
        window.location.href = `https://v3.appmaker.xyz/apps/${projectId}/subscription`;
        return false;
      }
      try {
        trackEvent(
          'payment_start',
          { project_id: projectId, email },
          {
            mautic: true
          }
        );
      } catch (error) { }
      setModal(false);
      const chargebeePlanId = yearly
        ? plans[planId].chargebeePlanId.yearly
        : plans[planId].chargebeePlanId.monthly;
      const cbInstance = window.Chargebee.init({
        site:
          process.env.NODE_ENV === 'production' ? 'appmaker' : 'appmaker-test'
      });
      cbInstance.openCheckout({
        hostedPage: () => {
          return fetch(
            `${API_URL}/v2/payment/${projectId}/chargebee-hosted-page`,
            {
              credentials: 'include',
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                plan: chargebeePlanId
              })
            }
          )
            .then(response => {
              return response.json();
            })
            .then(response => {
              if (response.status && response.hosted_page) {
                return response.hosted_page;
              }
              if (response.pending_invoices) {
                window.location.href = `${API_URL}/v2/payment/${projectId}/collect-unpaid`;
                cbInstance.closeAll();
              } else {
                cbInstance.closeAll();
                // eslint-disable-next-line no-alert
                alert('Unable to process payment, please contact support');
              }
            });
        },
        error: () => {
          try {
            trackEvent('checkout_error', { project_id: projectId });
          } catch (error) { }
        },
        close: () => {
          // console.log('checkout closed');
        },
        success: hostedPageId => {
          return fetch(
            `${API_URL}/v2/payment/${projectId}/chargebee-success?hostedPageId=${hostedPageId}`,
            {
              credentials: 'include'
            }
          )
            .then(response => {
              return response.json();
            })
            .then(() => {
              try {
                trackEvent('checkout_complete', { project_id: projectId });
              } catch (error) { }
              window.location.reload();
            })
            .catch(() => {
              try {
                trackEvent(
                  'checkout_error',
                  { project_id: projectId, email },
                  {
                    mautic: true
                  }
                );
              } catch (error) { }
            });
        },
        step: value => {
          try {
            trackEvent('checkout_step', { step: value, project_id: projectId });
          } catch (error) { }
        }
      });
    }

    if (template === 'woocommerce') {
      return (
        <React.Fragment>
          {showPaymentButton && (
            <Button className={paymentButtonClass} onClick={toggle}>
              {paymentText}
            </Button>
          )}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} className="border-bottom-0" />
            <ModalBody className="p-3">
              <Container style={{ textAlign: 'center' }}>
                <Button
                  color="success"
                  onClick={proceed}
                  className="px-5"
                >
                  <React.Fragment>View plans</React.Fragment>
                </Button>
              </Container>
            </ModalBody>
          </Modal>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {showPaymentButton && (
          <Button className={paymentButtonClass} onClick={toggle}>
            {paymentText}
          </Button>
        )}
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} className="border-bottom-0">
            Choose a plan for your store
          </ModalHeader>
          <ModalBody className="p-3">
            <Container>
              <Row className="justify-content-center">
                {Object.keys(plans).map(id => {
                  const {
                    planName,
                    monthlyPrice,
                    yearlyPrice,
                    revenueDisc,
                    perfectFor,
                    skipMonthly = false,
                    yearlyPriceInMonth = false
                  } = plans[id];
                  return (
                    <Col
                      className="px-1 text-center"
                      xs="12"
                      sm="12"
                      md="3"
                      lg="3"
                      key={`plan-${id}`}
                    >
                      <PlanListing
                        id={id}
                        onSelect={() => {
                          if (skipMonthly === true) {
                            setSkipToggleButton(true);
                          } else {
                            setSkipToggleButton(false);
                          }
                          trackEvent('checkout_plan_selected', {
                            project_id: projectId,
                            plan: id
                          });
                          setPlanId(id);
                        }}
                        planName={planName}
                        price={
                          !skipMonthly && yearly === false
                            ? monthlyPrice
                            : yearlyPrice
                        }
                        revenueDisc={revenueDisc}
                        perfectFor={perfectFor}
                        showYearOnly={
                          yearlyPriceInMonth
                            ? false
                            : skipMonthly
                              ? true
                              : yearly
                        }
                        defaultChecked={id === planId}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            {!skipToggleButton && (
              <React.Fragment>
                <p className="mb-0">
                  <b>Monthly Billing</b>
                  {/* <small> (Save upto 20%)</small> */}
                </p>
                <CustomInput
                  type="switch"
                  id="switchBillingType"
                  name="billingType"
                  checked={yearly}
                  label={yearly === false ? (<b>Annual Billing (20% Off)</b>) : (<b>Annual Billing (20% Off)</b>)}
                  className="mr-4 cursor-pointer"
                  onChange={billToggle}
                />
              </React.Fragment>
            )}
            <Button color="success" onClick={proceed} className="px-5">
              Proceed
            </Button>
          </ModalFooter>
          <div className="choose-plan-footer text-center py-1 bg-light">
            {/* <p className="mb-0">Large business with custome requirements? <a href="#" className="btn btn-outline-primary btn-pill btn-sm ml-3">Contact us</a></p> */}
            <a
              href={`https://appmaker.xyz/${template}/pricing/`}
              className="btn btn-link mb-0"
              target="_blank"
              rel="noopener noreferrer"
            >
              Detailed pricing
{' '}
              <small>
                <i className="fas fa-external-link-alt" />
              </small>
            </a>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
);

export default ChoosePlan;
