import React from 'react';
import {
  InspectorControls,
  InnerBlocks,
  RichText
} from '@wordpress/block-editor';
import { PanelBody, ToggleControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
import ActionSelect from '../../../hooks/action/components/ActionSelect';
// Panel body
import PanelBodyComponent from './panel';
import { extractTextFromHTML } from '../../helper';

const onChangeAttributes = (value, setAttributes) => {
  setAttributes(value);
};
export default props => {
  const { setAttributes, className, attributes } = props;
  const { showTitle, showViewMoreButton, title, ctaText } = attributes;
  return (
    <div className={className}>
      <PanelBodyComponent
        {...props}
        onChangeAttribute={value => {
          onChangeAttributes(value, setAttributes);
        }}
      />
      <div className="block-title-part d-flex p-2 justify-content-between">
        <div className="block-title my-auto">
          {showTitle && (
            <RichText
              allowedFormats={[]}
              value={title}
              withoutInteractiveFormatting
              onChange={text => {
                setAttributes({ title: extractTextFromHTML(text) });
              }}
              placeholder="Product scroller title"
            />
          )}
        </div>
        <div className="view-more-button">
          <div className="btn btn-light btn-sm">
            {showViewMoreButton && (
              <RichText
                withoutInteractiveFormatting
                allowedFormats={[]}
                value={ctaText}
                onChange={ctaTexts => {
                  setAttributes({ ctaText: extractTextFromHTML(ctaTexts) });
                }}
                placeholder="View More Button"
              />
            )}
          </div>
        </div>
      </div>
      <InnerBlocks allowedBlocks={['appmaker/productscroller-grid-item']} />
    </div>
  );
};
