/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { withSelect } from '@wordpress/data';
import { InspectorControls, RichText } from '@wordpress/block-editor';
import { PanelBody, ToggleControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
import { extractTextFromHTML } from '../../helper';
import ActionSelect from '../../../hooks/action/components/ActionSelect';
import { getRemoteStoreData } from '../../../lib/apiFunctions';

const ACTION_DATA_SOURCE = {
  dataSource: {
    link: 'category',
    appendLanguage: true
  },
  actionLabel: 'Choose Posts',
  labelIfNoData: 'No posts found',
  labelIfNotSelected: 'Select a post'
};
const RenderBlockContent = ({ imageBlocks, isLoading }) => {
  // FIXME LOADING
  if (isLoading) {
    return (
      <div
        style={{
          height: '190px',
          background: '#f5f7fb',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        Loading...
      </div>
    );
  }
  if (!imageBlocks) {
    return (
      <div
        style={{
          height: '190px',
          background: '#f5f7fb',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {ACTION_DATA_SOURCE.labelIfNotSelected}
      </div>
    );
  }
  if (imageBlocks && imageBlocks.length === 0) {
    return (
      <div
        style={{
          height: '190px',
          background: '#f5f7fb',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {ACTION_DATA_SOURCE.labelIfNoData}
      </div>
    );
  }
  return (
    imageBlocks &&
    imageBlocks.map(item => {
      return (
        <div
          className="rounded shadow-sm ml-2 overflow-hidden"
          key={item.id}
          style={{ width: '300px', display: 'inline-block' }}
        >
          <img
            alt="..."
            width="100%"
            height="150px"
            style={{ objectFit: 'cover' }}
            src={item.src}
          />
          <div className="p-2" style={{ whiteSpace: 'initial' }}>
            <h6 className="ellipsis ellipsis-1">{item.title}</h6>
            <p className="small-label-text mb-0 ellipsis">{item.excerpt}</p>
          </div>
        </div>
      );
    })
  );
};
const RenderSideBar = ({
  setAttributes,
  className,
  attributes,
  imageBlocks,
  action,
  projectId
}) => {
  const {
    showTitle,
    showViewMoreButton,
    title,
    ctaText,
    id,
    link,
    hasChild,
    childId,
    labelId
  } = attributes;

  return (
    <div className={className}>
      <div className="block-title-part d-flex p-2 justify-content-between">
        <div className="block-title my-auto">
          {showTitle && (
            <RichText
              allowedFormats={[]}
              value={title}
              withoutInteractiveFormatting
              onChange={text => {
                setAttributes({ title: extractTextFromHTML(text) });
              }}
              placeholder="Product Scroller title"
            />
          )}
        </div>
        <div className="view-more-button">
          <div className="btn btn-light btn-sm">
            {showViewMoreButton && (
              <RichText
                allowedFormats={[]}
                value={ctaText}
                withoutInteractiveFormatting
                onChange={ctaTexts => {
                  setAttributes({ ctaText: extractTextFromHTML(ctaTexts) });
                }}
                placeholder="View More Button"
              />
            )}
          </div>
        </div>
      </div>
      <InspectorControls>
        <PanelBody>
          <ToggleControl
            label={__('Show View more button', 'newspack-blocks')}
            checked={showViewMoreButton}
            onChange={() => {
              setAttributes({ showViewMoreButton: !showViewMoreButton });
            }}
          />
          <ToggleControl
            label={__('Show Title', 'newspack-blocks')}
            checked={showTitle}
            onChange={() => {
              setAttributes({ showTitle: !showTitle });
            }}
          />
          {ACTION_DATA_SOURCE.actionLabel}
          <ActionSelect
            value={{ id, label: labelId }}
            dataSource={ACTION_DATA_SOURCE.dataSource}
            projectId={projectId}
            onChange={data => {
              setAttributes({
                id: data.id,
                labelId: data.label
              });
            }}
          />
        </PanelBody>
      </InspectorControls>
    </div>
  );
};

const PreBuildScroller = props => {
  const { attributes = {} } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const loadData = async id => {
    setIsLoading(true);
    try {
      const response = await getRemoteStoreData(
        props.projectId,
        `pre_build_post/${id}`
      );

      setData(
        response
          .map(item => {
            if (item.featured_media && item.featured_media !== 'false') {
              return {
                src: item.featured_media,
                title: item.title.rendered,
                excerpt: item.excerpt.rendered
              };
            }
            return null;
          })
          .filter(item => item !== null)
      );
    } catch (error) {}
    setIsLoading(false);
  };
  useEffect(() => {
    if (attributes.id) {
      loadData(attributes.id);
    }
  }, [attributes.id]);

  return (
    <div>
      <RenderSideBar {...props} />
      <div className="product-scroller-container">
        <RenderBlockContent isLoading={isLoading} imageBlocks={data} />
      </div>
    </div>
  );
};

const Wrapper = withSelect(select => {
  const project = select('appmaker/core').getCurrentProject();
  const url = project.meta.URL;
  const apiKey = project.meta.API_KEY;
  return { url, apiKey, projectId: project.id };
})(PreBuildScroller);
export default Wrapper;
