import React from 'react';
import { Button, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';

import constants from '../../../../lib/constants';
import * as ROUTES from '../../../../constants/routes';
import { trackEvent } from '../../../../utils/tracker';
import ChoosePlan from '../../subscriptionData/choosePlan';

function subscriptionDetails(status) {
  switch (status) {
    case constants.PROJECT_PAYMENT_PAID:
      return <Badge color="info">Paid</Badge>;
    case constants.PROJECT_PAYMENT_TRIAL:
      return <Badge color="warning">Trial</Badge>;
    case constants.PROJECT_PAYMENT_EXPIRED:
      return <Badge color="danger">Payment Expired</Badge>;
    case constants.PROJECT_PAYMENT_EXPIRED_COMPLETE:
      return <Badge color="danger">Payment Expired</Badge>;
    case constants.PAYMENT_TYPE_FREE:
      return <Badge color="warning">Free</Badge>;
    case constants.PAYMENT_TYPE_ONETIME:
      return <Badge color="warning">One-Time</Badge>;
    case constants.PAYMENT_TYPE_SAAS:
      return <Badge color="warning">SAAS</Badge>;
    default:
      return <Badge color="danger">Not Available</Badge>;
  }
}
export default function ViewSubscriptionWidget({
  project: {
    paymentStatus,
    id,
    paidTransactions,
    template,
    configure: { highValue: isHighValue = false }
  }
}) {
  const paymentRef = React.createRef();
  const popUpIntercom = () => {
    trackEvent('dashboard_clicked_offer_availability', { project_id: id });
    const customMessage = `Hey Team Appmaker,\n Are there any exclusive deals and offers today for me?`;
    if (window && window.Intercom)
      window.Intercom('showNewMessage', customMessage);
  };
  return (
    <div className="dashboard-box rounded bg-success text-white mt-2 p-3 position-relative subscription-card overflow-hidden h-100">
      <h5 className="mb-3">
        <i className="far fa-credit-card mr-2" />
        Subscription
        {/* <h6 className="ml-2 d-inline">{subscriptionDetails(paymentStatus)}</h6> */}
      </h5>
      <div>
        <button
          type="button"
          onClick={() => {
            paymentRef.current.toggle();
          }}
          className="btn btn-light mt-2 mr-2"
        >
          {paymentStatus !== '5' ? 'Make Payment' : 'Upgrade subscription'}
        </button>
        {paymentStatus === constants.PROJECT_PAYMENT_TRIAL &&
          template === 'woocommerce' && (
            <React.Fragment>
              {!isHighValue && (
                <button
                  type="button"
                  className="btn btn-light mt-2 mr-2"
                  onClick={popUpIntercom}
                >
                  <span>Check offer availability</span>
                </button>
              )}
              {isHighValue && (
                <a
                  type="button"
                  className="btn btn-dark mt-2"
                  href="https://calendly.com/appmaker-vishnu/appmaker-walkthrough"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-headset mr-2 fa-beat" />
                  <span>Talk to an Expert</span>
                </a>
              )}
            </React.Fragment>
          )}
      </div>
      <ChoosePlan
        template={template}
        showPaymentButton={false}
        projectId={id}
        ref={paymentRef}
      />
    </div>
  );
}
