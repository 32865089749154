import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <rect x="2" y="2" width="9" height="9" rx="1" fill="#6D65FE" />
    <rect x="2" y="13" width="9" height="9" rx="1" fill="#5576FB" />
    <rect x="13" y="2" width="9" height="9" rx="1" fill="#5576FB" />
    <rect x="13" y="13" width="9" height="9" rx="1" fill="#6D65FE" />
    <rect x="4" y="9" width="5" height="1" rx="0.5" fill="#8CA0F7" />
    <rect x="4" y="20" width="5" height="1" rx="0.5" fill="#8CA0F7" />
    <rect x="15" y="9" width="5" height="1" rx="0.5" fill="#8CA0F7" />
    <rect x="15" y="20" width="5" height="1" rx="0.5" fill="#8CA0F7" />
    <path
      opacity="0.7"
      d="M8.92003 4.24823L6.49957 5.5021L4.07912 4.24823C4.12879 4.17127 4.19962 4.10695 4.2849 4.06138L6.20533 3.06911C6.29508 3.0238 6.39639 3 6.49944 3C6.60249 3 6.70379 3.0238 6.79355 3.06911L8.71398 4.06138C8.79925 4.10695 8.87009 4.17127 8.91976 4.24823H8.92003Z"
      fill="#C4C0FF"
    />
    <path
      opacity="0.7"
      d="M6.50013 5.50087V7.99999C6.40741 7.99986 6.31593 7.98095 6.23254 7.94468L4.29397 6.94281C4.20476 6.89661 4.13066 6.83026 4.07907 6.7504C4.02749 6.67055 4.00022 6.57997 4 6.48773V4.514C4.00049 4.42077 4.02784 4.32924 4.0794 4.24823L6.50013 5.50087Z"
      fill="#A6A1FF"
    />
    <path
      opacity="0.7"
      d="M8.99999 4.51401V6.48774C8.99982 6.58002 8.97258 6.67065 8.92099 6.75055C8.8694 6.83046 8.79528 6.89684 8.70602 6.94307L6.75867 7.94837C6.67761 7.9822 6.5893 7.99982 6.49986 8V5.50088L8.92032 4.24701C8.97221 4.32836 8.99966 4.42034 8.99999 4.51401Z"
      fill="#827AFF"
    />
    <path
      opacity="0.7"
      d="M19.92 4.24823L17.4996 5.5021L15.0791 4.24823C15.1288 4.17127 15.1996 4.10695 15.2849 4.06138L17.2053 3.06911C17.2951 3.0238 17.3964 3 17.4994 3C17.6025 3 17.7038 3.0238 17.7935 3.06911L19.714 4.06138C19.7993 4.10695 19.8701 4.17127 19.9198 4.24823H19.92Z"
      fill="#C4C0FF"
    />
    <path
      opacity="0.7"
      d="M17.5001 5.50087V7.99999C17.4074 7.99986 17.3159 7.98095 17.2325 7.94468L15.294 6.94281C15.2048 6.89661 15.1307 6.83026 15.0791 6.7504C15.0275 6.67055 15.0002 6.57997 15 6.48773V4.514C15.0005 4.42077 15.0278 4.32924 15.0794 4.24823L17.5001 5.50087Z"
      fill="#A6A1FF"
    />
    <path
      opacity="0.7"
      d="M20 4.51401V6.48774C19.9998 6.58002 19.9726 6.67065 19.921 6.75055C19.8694 6.83046 19.7953 6.89684 19.706 6.94307L17.7587 7.94837C17.6776 7.9822 17.5893 7.99982 17.4999 8V5.50088L19.9203 4.24701C19.9722 4.32836 19.9997 4.42034 20 4.51401Z"
      fill="#827AFF"
    />
    <path
      opacity="0.7"
      d="M19.92 15.2482L17.4996 16.5021L15.0791 15.2482C15.1288 15.1713 15.1996 15.1069 15.2849 15.0614L17.2053 14.0691C17.2951 14.0238 17.3964 14 17.4994 14C17.6025 14 17.7038 14.0238 17.7935 14.0691L19.714 15.0614C19.7993 15.1069 19.8701 15.1713 19.9198 15.2482H19.92Z"
      fill="#C4C0FF"
    />
    <path
      opacity="0.7"
      d="M17.5001 16.5009V19C17.4074 18.9999 17.3159 18.981 17.2325 18.9447L15.294 17.9428C15.2048 17.8966 15.1307 17.8303 15.0791 17.7504C15.0275 17.6705 15.0002 17.58 15 17.4877V15.514C15.0005 15.4208 15.0278 15.3292 15.0794 15.2482L17.5001 16.5009Z"
      fill="#A6A1FF"
    />
    <path
      opacity="0.7"
      d="M20 15.514V17.4877C19.9998 17.58 19.9726 17.6706 19.921 17.7506C19.8694 17.8305 19.7953 17.8968 19.706 17.9431L17.7587 18.9484C17.6776 18.9822 17.5893 18.9998 17.4999 19V16.5009L19.9203 15.247C19.9722 15.3284 19.9997 15.4203 20 15.514Z"
      fill="#827AFF"
    />
    <path
      opacity="0.7"
      d="M8.92003 15.2482L6.49957 16.5021L4.07912 15.2482C4.12879 15.1713 4.19962 15.1069 4.2849 15.0614L6.20533 14.0691C6.29508 14.0238 6.39639 14 6.49944 14C6.60249 14 6.70379 14.0238 6.79355 14.0691L8.71398 15.0614C8.79925 15.1069 8.87009 15.1713 8.91976 15.2482H8.92003Z"
      fill="#C4C0FF"
    />
    <path
      opacity="0.7"
      d="M6.50013 16.5009V19C6.40741 18.9999 6.31593 18.981 6.23254 18.9447L4.29397 17.9428C4.20476 17.8966 4.13066 17.8303 4.07907 17.7504C4.02749 17.6705 4.00022 17.58 4 17.4877V15.514C4.00049 15.4208 4.02784 15.3292 4.0794 15.2482L6.50013 16.5009Z"
      fill="#A6A1FF"
    />
    <path
      opacity="0.7"
      d="M8.99999 15.514V17.4877C8.99982 17.58 8.97258 17.6706 8.92099 17.7506C8.8694 17.8305 8.79528 17.8968 8.70602 17.9431L6.75867 18.9484C6.67761 18.9822 6.5893 18.9998 6.49986 19V16.5009L8.92032 15.247C8.97221 15.3284 8.99966 15.4203 8.99999 15.514Z"
      fill="#827AFF"
    />
  </svg>
);
