import React, { useState } from 'react';
import './style.css';
import { Link } from 'react-router-dom';

const SupportSideBar = () => {
  const [show, setShow] = useState(false);
  const toggle = () => {
    setShow(!show);
  };
  return (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-outline-light text-dark btn-sm mr-3"
        onClick={() => {
          toggle();
        }}
      >
        <i className="fas fa-question-circle text-primary mr-1" /> Help
      </button>
      <div
        className={`dialogue-glass ${show === true && 'show'}`}
        onClick={() => {
          toggle();
        }}
      />
      <div className={`support-sidebar ${show === true && 'show'}`}>
        <div className="p-3">
          <div className="d-flex justify-content-between mb-3">
            <h6 className="text-center text-muted mb-3 my-auto">HELP</h6>
            <i
              className="fas fa-times text-muted p-2 my-auto cursor-pointer"
              onClick={() => {
                toggle();
              }}
            />
          </div>
          <a
            href="https://www.youtube.com/c/AppilderAppCreator/videos"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-light rounded-lg text-decoration-none p-3 d-flex my-1"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#6C63FF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="my-auto mr-3"
            >
              <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />
              <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" />
            </svg>
            <span className="flex-grow-1">
              <h5 className="mb-0 text-dark">Video Resources</h5>
              <small className="text-muted">Useful video tutorials</small>
            </span>
            <svg
              width="48"
              height="48"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#C7C7C7"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="my-auto"
            >
              <polyline points="9 18 15 12 9 6" />
            </svg>
          </a>
          <a
            href="https://appmaker.xyz/docs/"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-light rounded-lg text-decoration-none p-3 d-flex my-1"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#6C63FF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="my-auto mr-3"
            >
              <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
              <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
            </svg>
            <span className="flex-grow-1">
              <h5 className="mb-0 text-dark">Knowledge base</h5>
              <small className="text-muted">Detailed documentation</small>
            </span>
            <svg
              width="48"
              height="48"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#C7C7C7"
              strokeWidth="0.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="my-auto"
            >
              <polyline points="9 18 15 12 9 6" />
            </svg>
          </a>
          <a
            href="https://appmaker.freshdesk.com/support/tickets/new"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-light rounded-lg text-decoration-none p-3 d-flex my-1"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#6C63FF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="my-auto mr-3"
            >
              <circle cx="12" cy="12" r="10" />
              <circle cx="12" cy="12" r="4" />
              <line x1="4.93" y1="4.93" x2="9.17" y2="9.17" />
              <line x1="14.83" y1="14.83" x2="19.07" y2="19.07" />
              <line x1="14.83" y1="9.17" x2="19.07" y2="4.93" />
              <line x1="14.83" y1="9.17" x2="18.36" y2="5.64" />
              <line x1="4.93" y1="19.07" x2="9.17" y2="14.83" />
            </svg>
            <span className="flex-grow-1">
              <h5 className="mb-0 text-dark">Support</h5>
              <small className="text-muted">Create a support ticket</small>
            </span>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SupportSideBar;
