import * as ROUTES from '../constants/routes';

const BaseUrl = 'https://appmaker.xyz/api/v3';
// const BaseUrl = 'http://anees.working:8080/api/v3';
export const BaseUrlForGetProjectById = ROUTES.API_URL;
// const BaseUrlForGetProjectById = 'http://localhost:3000';

export const getApiForProjects = (active = true) => {
  const apiForAppActiveApp = `${BaseUrl}/projects?isActiveProjects=${active}`;
  return fetch(apiForAppActiveApp, { credentials: 'include' });
};

export const getApiForDataByProjectId = id => {
  const getDataByProjectId = `${BaseUrlForGetProjectById}/api/${id}`;
  return fetch(getDataByProjectId, { credentials: 'include' });
};
// subscription page api
export const getSubscription = (id, callback) => {
  fetch(`${BaseUrl}/orders/subscription?project_id=${id}`, {
    credentials: 'include'
  })
    .then(response => response.json())
    .then(invoices => {
      return callback(true, invoices);
    })
    .catch(error => {
      return callback(false, error);
    });
};
// invoice details api
export const getInvoice = (invoiceId, callback) => {
  fetch(`${BaseUrl}/orders/invoice?id=${invoiceId}`, {
    credentials: 'include'
  })
    .then(response => response.json())
    .then(data => {
      return callback(true, data);
    })
    .catch(error => {
      return callback(false, error);
    });
};
export const getAccoutSetting = () => {
  return fetch(`${BaseUrl}/settings/profile`, { credentials: 'include' });
};
export const getBilling = () => {
  return fetch(`${BaseUrl}/settings/billing`, { credentials: 'include' });
};
export const updateAccountSettings = data => {
  const option = {
    credentials: 'include',
    method: 'POST',
    body: data
  };
  return fetch(`${BaseUrl}/settings/profile`, option);
};
export const updateBillingSettings = data => {
  const option = {
    credentials: 'include',
    method: 'POST',
    body: data
  };
  return fetch(`${BaseUrl}/settings/billing`, option);
};

export const getAppSettingss = appId => {
  return fetch(`${BaseUrlForGetProjectById}/remote_store/${appId}/settings`, {
    credentials: 'include'
  });
};

export const getAppSettings = async appId => {
  try {
    // const url = `${BaseUrlForGetProjectById}/remote_store/${appId}/settings`;
    const url = `${BaseUrlForGetProjectById}/v2/projects/${appId}/settings`;
    const response = await fetch(url, {
      credentials: 'include'
    });
    const respJson = await response.json();
    if (respJson.status === true) {
      return Promise.resolve(respJson.body);
    }
    throw new Error('Something went wrong');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAppSetting = async (appId, data) => {
  try {
    // const url = `${BaseUrlForGetProjectById}/remote_store/${appId}/settings`;
    const url = `${BaseUrlForGetProjectById}/v2/projects/${appId}/settings`;
    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data })
    });
    const respJson = await response.json();
    if (respJson.status === true) {
      return Promise.resolve(respJson);
    }

    return Promise.reject(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};
// Api for in-app-page
export const getInAppPage = async appId => {
  const url = `${BaseUrlForGetProjectById}/remote_store/${appId}/inAppPages/home`;
  const option = { credentials: 'include' };
  try {
    const response = await fetch(url, option);
    const resp = await response.json();
    return Promise.resolve({ status: true, data: resp });
  } catch (error) {
    return Promise.reject(new Error('Error in calling api'));
  }
};

// Api for settings of widget for banner
export const getSettingsBanner = async appId => {
  const url = `${BaseUrlForGetProjectById}/api_proxy/${appId}?url=uploadMedia&search=`;
  const option = { credentials: 'include' };
  try {
    const response = await fetch(url, option);
    const resp = await response.json();
    return Promise.resolve({ status: true, data: resp });
  } catch (eroor) {
    return Promise.reject(new Error('Error calling api'));
  }
};

// Api for open product
export const openProduct = async (appId, productName) => {
  const option = { credentials: 'include' };
  let url;
  if (productName === undefined)
    url = `${BaseUrlForGetProjectById}/api_proxy/${appId}?url=products&search=`;
  else
    url = `${BaseUrlForGetProjectById}/api_proxy/${appId}?url=products&search=${productName}`;
  try {
    const response = await fetch(url, option);
    const repJson = await response.json();
    return repJson;
  } catch (error) {
    return Promise.reject(new Error('Error when calling api'));
  }
};

// Api for product category
export const productCategory = async (appId, category) => {
  const option = { credentials: 'include' };
  let url;
  if (category === undefined)
    url = `${BaseUrlForGetProjectById}/api_proxy/${appId}?url=product_category&search=`;
  else
    url = `${BaseUrlForGetProjectById}/api_proxy/${appId}?url=product_category&search=${category}`;
  try {
    const response = await fetch(url, option);
    const repJson = await response.json();
    return repJson;
  } catch (error) {
    return Promise.reject(new Error('Error when calling api'));
  }
};
// Api for product tag
export const productTag = async (appId, category) => {
  const option = { credentials: 'include' };
  let url;
  if (category === undefined)
    url = `${BaseUrlForGetProjectById}/api_proxy/${appId}?url=product_tag&search=`;
  else
    url = `${BaseUrlForGetProjectById}/api_proxy/${appId}?url=product_tag&search=${category}`;
  try {
    const response = await fetch(url, option);
    const repJson = await response.json();
    return repJson;
  } catch (error) {
    return Promise.reject(new Error('Error when calling api'));
  }
};
// Api for open post category
export const openPostCategory = async (appId, category) => {
  const option = { credentials: 'include' };
  let url;
  if (category === undefined)
    url = `${BaseUrlForGetProjectById}/api_proxy/${appId}?url=category&search=`;
  else
    url = `${BaseUrlForGetProjectById}/api_proxy/${appId}?url=category&search=${category}`;
  try {
    const response = await fetch(url, option);
    const repJson = await response.json();
    return repJson;
  } catch (error) {
    return Promise.reject(new Error('Error when calling api'));
  }
};
// Api for open post/page
export const openPostPage = async (appId, category) => {
  const option = { credentials: 'include' };
  let url;
  if (category === undefined)
    url = `${BaseUrlForGetProjectById}/api_proxy/${appId}?url=posts&search=`;
  else
    url = `${BaseUrlForGetProjectById}/api_proxy/${appId}?url=posts&search=${category}`;
  try {
    const response = await fetch(url, option);
    const repJson = await response.json();
    return repJson;
  } catch (error) {
    return Promise.reject(new Error('Error when calling api'));
  }
};

// Api for the inApp Pages actions
export const apiProxy = async (appId, urlKey, data, extraParams) => {
  const option = { credentials: 'include' };
  let url;
  if (data !== undefined && typeof data === 'string' && data.length === 0) {
    url = `${BaseUrlForGetProjectById}/v2/projects/${appId}/api-proxy/${urlKey}`;
    if (extraParams !== undefined) {
      url += `?${extraParams}`;
    }
  } else {
    url = `${BaseUrlForGetProjectById}/v2/projects/${appId}/api-proxy/${urlKey}?search=${data}`;
    if (extraParams !== undefined) {
      url += `&${extraParams}`;
    }
  }
  try {
    const response = await fetch(url, option);
    const repJson = await response.json();
    return repJson;
  } catch (error) {
    console.error(error);
    return Promise.reject(new Error('Error when calling api'));
  }
};
export const newApiProxy = async (appId, urlKey) => {
  const option = { credentials: 'include' };
  const url = `${BaseUrlForGetProjectById}/v2/projects/${appId}/api-proxy/${urlKey}`;
  try {
    const response = await fetch(url, option);
    const repJson = await response.json();
    return repJson;
  } catch (error) {
    console.error(error);
    return Promise.reject(new Error('Error when calling api'));
  }
};
// uploading the media
export const uploadMedia = async (appId, files) => {
  const url = `${BaseUrlForGetProjectById}/remote_store/${appId}/media/uploadMedia`;
  const data = new FormData();
  data.append('file', files);
  try {
    const option = {
      credentials: 'include',
      method: 'POST',
      body: data
    };
    const response = await fetch(url, option);
    const repJson = await response.json();
    return repJson;
  } catch (error) {
    return Promise.reject(new Error('Error when calling upload media'));
  }
};

export const addNewPage = async (appId, data, inAppPageId) => {
  let url;
  if (inAppPageId === undefined)
    // url = `${BaseUrlForGetProjectById}/remote_store/${appId}/inAppPages`;
    url = `${BaseUrlForGetProjectById}/v2/projects/${appId}/in-app-pages`;
  else
    url = `${BaseUrlForGetProjectById}/remote_store/${appId}/inAppPages/${inAppPageId}`;
  const option = {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(url, option);
    const resp = await response.json();
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

// To get the prebuild product scroller data
export const getPreBuildProductScrollerData = async (
  URL,
  apiKey,
  id,
  appId
) => {
  // const url = `${URL}/?api_key=${apiKey}&rest_route=/appmaker-wc/v1/products/lists/${id}`;
  const url = `${BaseUrlForGetProjectById}/remote_store/${appId}/pre_build_product/${id}`;
  const option = { method: 'GET', credentials: 'include' };
  try {
    const response = await fetch(url, option);
    const resp = await response.json();

    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

// product scroller
export const getProductScrollerData = async (
  URL,
  apiKey,
  id,
  projectId,
  languageKey
) => {
  // const url = `${URL}/?api_key=${apiKey}&rest_route=/appmaker-wc/v1/products&include=${id}`;
  let url = `${BaseUrlForGetProjectById}/api_proxy/${projectId}/?url=product_scroller&include=${id}`;
  if (languageKey !== undefined) {
    url += `&language=${languageKey}`;
  }
  const option = { method: 'GET', credentials: 'include' };
  try {
    const response = await fetch(url, option);
    const resp = await response.json();
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};
// To take the backup of the old version inAppPages of the app
export const backupTheV1Data = async appId => {
  const url = `http://us-central1-appilder-com.cloudfunctions.net/backup-inappage/?projectId=${appId}`;
  try {
    const response = await fetch(url, { method: 'GET' });
    const resp = await response.json();
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRemoteStoreData = async (projectId, urlKey) => {
  const url = `${BaseUrlForGetProjectById}/remote_store/${projectId}/${urlKey}`;
  const option = { method: 'GET', credentials: 'include' };
  const response = await fetch(url, option);
  const resp = await response.json();
  return resp;
};

// Download link for android app
export const downloadApp = (appId, preview, token) => {
  let url = `${ROUTES.URL}/dashboard/apps/download/${appId}?preview=${preview}`;
  if (token !== undefined) {
    url += `&token=${token}`;
  }
  return url;
};
// Preview for IOS app
export const previewApp = appId => {
  const url = `${ROUTES.URL}/api/v3/projects/app-preview?appId=${appId}`;
  return url;
};
// app buid status
export const appBuildStatus = async projectId => {
  const url = `${ROUTES.URL}/api/v3/projects/status?project=${projectId}`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const resp = await response.json();
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Push notification
export const pushNotification = async (appId, data) => {
  // const url = `${BaseUrlForGetProjectById}/api_proxy/${appId}/sendPush`;
  const url = `${BaseUrlForGetProjectById}/v2/projects/${appId}/notifications/send`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(data)
    });
    const resp = await response.json();
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const checkPluginStatus = async projectId => {
  return fetch(`${ROUTES.API_URL}/v2/projects/${projectId}/plugin-status`, {
    credentials: 'include'
  })
    .then(res => res.json())
    .then(({ plugin_configured = false, version }) => {
      // eslint-disable-next-line camelcase
      if (plugin_configured) {
        return Promise.resolve({ plugin_configured, version });
      }
      return Promise.reject();
    });
};

export const sendPluginStatus = async (projectId, status) => {
  const formData = new FormData();
  formData.append('project_id', projectId);
  formData.append('status', status);
  return fetch(`${ROUTES.URL}/api/v3/projects/update-plugin-status`, {
    method: 'POST',
    body: formData,
    credentials: 'include'
  });
};

export const logout = async () => {
  const url = `${ROUTES.URL}/`;
};

export const uploadIosCertificate = async (projectId, data) => {
  try {
    const url = `${BaseUrl}/projects/upload-certificate?projectId=${projectId}`;
    const response = await fetch(url, {
      method: 'POST',
      body: data,
      credentials: 'include'
    });
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 *
 * @param {*} appId its app id not project id
 * @param {*} data // auhKey ( file ), issuerId, apiKey (Form data)
 */
export const uploadIosAuthKey = async ({
  appId,
  authKey,
  apiKey,
  issuerId
}) => {
  try {
    const data = new FormData();
    data.append('authKey', authKey);
    data.append('apiKey', apiKey);
    data.append('issuerId', issuerId);
    const url = `${BaseUrl}/projects/upload-ios-auth-key/?app_id=${appId}`;
    const response = await fetch(url, {
      method: 'POST',
      body: data,
      credentials: 'include'
    });
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 *
 * @param {*} appId its app id not project id
 */
export const buildIpaFile = async appId => {
  try {
    const url = `${BaseUrl}/projects/build-ipa-file/?app_id=${appId}`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 *
 * @param {*} appId its app id not project id
 */
export const uploadIpaFile = async appId => {
  try {
    const url = `${BaseUrl}/projects/ios-ipa-upload/?app_id=${appId}`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 *
 * @param {*} appId its app id not project id
 */
export const ipaUploadStatus = async appId => {
  try {
    const url = `${BaseUrl}/projects/ios-ipa-upload-status/?app_id=${appId}`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 *
 * @param {*} appId its app id not project id
 */
export const iosCreateCertStatus = async appId => {
  try {
    const url = `${BaseUrl}/projects/ios-create-cert-status/?app_id=${appId}`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteProjectInManageList = async (projectId, recover) => {
  try {
    const url = `${BaseUrl}/projects/delete?projectId=${projectId}&recover=${
      recover === undefined ? false : recover
    }`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const resp = await response.json();
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSalesData = async (projectId, startDate, endDate) => {
  try {
    let url = `${ROUTES.API_URL}/v2/projects/${projectId}/salesData`;
    if (startDate && endDate) {
      url += `?startDate=${startDate}&endDate=${endDate}`;
    }
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const resp = await response.json();
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPostListData = async projectId => {
  // return `${project.meta.WORDPRESS_URL}/index.php/wp-json/appmaker-wp/v1/posts?api_key=${project.meta.API_KEY}&per_page=50`;
  const url = `${ROUTES.API_URL}/api_proxy/${projectId}/?url=posts-all&_locale=user&per_page=50`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const resp = await response.json();
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const clone = async data => {
  try {
    const url = `${BaseUrlForGetProjectById}/v2/clone/${data}`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Customer data tab
 * @param {*} data
 */
export const customerDataTabData = async projectId => {
  try {
    const url = `${BaseUrlForGetProjectById}/v2/customer-data/${projectId}`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const respJson = await response.json();
    return Promise.resolve(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Sales data customer tab
 */
export const salesDataCustomerTab = async projectId => {
  try {
    const url = `${BaseUrlForGetProjectById}/v2/customer-data/${projectId}/sales-data`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const respJson = await response.json();
    return Promise.resolve(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};
/**
 * User details customer tab
 */

export const userDetailsCustomerTab = async projectId => {
  try {
    const url = `${BaseUrlForGetProjectById}/v2/customer-data/${projectId}/user-details`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const respJson = await response.json();
    return Promise.resolve(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Publish data customer tab
 */
export const publishDataCustomerTab = async projectId => {
  try {
    const url = `${BaseUrlForGetProjectById}/v2/customer-data/${projectId}/publish`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const respJson = await response.json();
    return Promise.resolve(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Website and plugin data customer tab
 */
export const websiteAndPluginCustomerTab = async projectId => {
  try {
    const url = `${BaseUrlForGetProjectById}/v2/customer-data/${projectId}/website-meta`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const respJson = await response.json();
    return Promise.resolve(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Social media
 */
export const socialMediaCustomerTab = async projectId => {
  try {
    const url = `${BaseUrlForGetProjectById}/v2/customer-data/${projectId}/social-media`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const respJson = await response.json();
    return Promise.resolve(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Freshdesk
 */
export const freshDeskCustomerTab = async projectId => {
  try {
    const url = `${BaseUrlForGetProjectById}/v2/customer-data/${projectId}/fresh-desk`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const respJson = await response.json();
    return Promise.resolve(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Credit limit
 */
export const creditLimitCustomerTab = async projectId => {
  try {
    const url = `${BaseUrlForGetProjectById}/v2/customer-data/${projectId}/credits`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    });
    const respJson = await response.json();
    return Promise.resolve(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setAsHomePage = async (appId, pageKey) => {
  const url = `${BaseUrlForGetProjectById}/v2/projects/${appId}/in-app-pages/set-as-home/${pageKey}`;
  const option = {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  try {
    const response = await fetch(url, option);
    const resp = await response.json();
    return Promise.resolve(resp);
  } catch (error) {
    return Promise.reject(error);
  }
};

/** *
 *
 *  New image api for shopify
 *
 *  */
export const getImageDetailsV2 = async (projectId, search) => {
  const url = `${BaseUrlForGetProjectById}/v2/projects/${projectId}/images?search=${search}`;
  const option = {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  try {
    const response = await fetch(url, option);
    const resp = await response.json();
    return Promise.resolve(resp);
  } catch (error) {
    return Promise.reject(error);
  }
};
/**
 *
 * Upload media v2 for sopify
 */
export const uploadMediaV2 = async (appId, files) => {
  const url = `${BaseUrlForGetProjectById}/v2/projects/${appId}/images/upload`;

  const data = new FormData();
  data.append('file', files);
  try {
    const option = {
      credentials: 'include',
      method: 'POST',
      body: data
    };
    const response = await fetch(url, option);
    const repJson = await response.json();
    return repJson;
  } catch (error) {
    return Promise.reject(new Error('Error when calling upload media'));
  }
};
