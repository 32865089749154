import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <rect x="2" y="2" width="9" height="6" rx="1" fill="#6D65FE" />
    <rect x="2" y="13" width="9" height="6" rx="1" fill="#5576FB" />
    <rect x="13" y="2" width="9" height="6" rx="1" fill="#5576FB" />
    <rect x="13" y="13" width="9" height="6" rx="1" fill="#6D65FE" />
    <rect x="2" y="9" width="9" height="1" rx="0.5" fill="#8CA0F7" />
    <rect x="2" y="20" width="9" height="1" rx="0.5" fill="#8CA0F7" />
    <rect x="13" y="9" width="9" height="1" rx="0.5" fill="#8CA0F7" />
    <rect x="13" y="20" width="9" height="1" rx="0.5" fill="#8CA0F7" />
  </svg>
);
